// Header.jsx
import React, { useState, useEffect } from "react";
import ProfileMenu from "./profile-menu";
import "./style.css";
import { Icon } from "@iconify/react";
import { fakeCredintials } from "../../data/fakeLoginData";
import { useDispatch, useSelector } from "react-redux";
import { Globe } from "../../assets/images/svg/images";
import { changeLanguage } from "../../store/language";
import { database } from "../../firebase"; // Adjust the path as necessary
import {
  ref,
  onChildAdded,
  get,
  query,
  orderByChild,
  limitToLast,
  update,
} from "firebase/database";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed

function Header({ active, setActive }) {
  const dispatch = useDispatch();
  const authenticatedUser = fakeCredintials;
  const language = useSelector((state) => state.language);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [voiceNotifications, setVoiceNotifications] = useState(false); // Toggle state

  useEffect(() => {
    // Reference to the 'invoices' node in Firebase Realtime Database
    const notificationsRef = ref(database, "invoices");

    // Query to fetch the latest 20 notifications
    const notificationsQuery = query(
      notificationsRef,
      orderByChild("timestamp"),
      limitToLast(20)
    );

    // Fetch initial notifications
    get(notificationsQuery)
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Convert Firebase data to an array with unique IDs
          const notificationsData = Object.entries(snapshot.val()).map(
            ([id, data]) => ({
              id,
              ...data,
            })
          );
          // Remove duplicates based on 'id'
          const uniqueNotificationsMap = new Map();
          notificationsData.forEach((notif) => {
            uniqueNotificationsMap.set(notif.id, notif);
          });
          const uniqueNotifications = Array.from(
            uniqueNotificationsMap.values()
          );

          // Sort notifications by timestamp descending (newest first)
          uniqueNotifications.sort((a, b) => b.timestamp - a.timestamp);

          setNotifications(uniqueNotifications);
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        // Optionally, uncomment to show error toast
        // toast.error(
        //   language === "ar"
        //     ? "حدث خطأ أثناء جلب الإشعارات"
        //     : "An error occurred while fetching notifications"
        // );
      });

    // Listen for new notifications in real-time
    const unsubscribe = onChildAdded(notificationsRef, (snapshot) => {
      const newNotification = {
        id: snapshot.key,
        ...snapshot.val(),
      };
      setNotifications((prev) => [newNotification, ...prev]);

      // Trigger a toast notification
      if (!newNotification?.read) {
        toast.info(
          language === "ar"
            ? `تم إنشاء فاتورة جديدة باسم "${
                newNotification.submation_name
              }" بواسطة ${newNotification.agent_name || "غير معروف"}.`
            : `New Invoice "${newNotification.submation_name}" created by ${
                newNotification.agent_name || "Unknown"
              }.`
        );

        // Play a voice notification only if enabled
        if (!newNotification?.read) {
          try {
            const audio = new Audio(
              "https://audio-previews.elements.envatousercontent.com/files/472198703/preview.mp3"
            );
            audio.play();
          } catch (err) {}
        }
      } 
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [database]); // Include voiceNotifications in dependencies

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  // Function to mark a notification as read
  const markAsRead = async (id) => {
    const notificationRef = ref(database, `invoices/${id}/read`);
    try {
      await update(notificationRef, { read: true });
      // Optionally, show a success toast
      // toast.success(
      //   language === "ar"
      //     ? "تم تحديث حالة الإشعار بنجاح"
      //     : "Notification status updated successfully"
      // );
    } catch (error) {
      console.error("Error marking notification as read:", error);
      // toast.error(
      //   language === "ar"
      //     ? "حدث خطأ أثناء تحديث حالة الإشعار"
      //     : "An error occurred while updating the notification status"
      // );
    }
  };
 
  // Calculate the number of unread notifications
  const unreadCount = notifications.filter((notif) => !notif.read).length;

  return ( 
    <header>
      <div className="div_header">
        <div className="list-toggle" onClick={() => setActive(!active)}>
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1693606296/menu_s5rljg.png"
            alt="Menu"
          />
        </div>
        <div className="person">
          {/* <img src={authenticatedUser?.image} alt="User" /> */}
          <div className="person__data">
            <span>{authenticatedUser?.name}</span>
            <span style={{ color: "var(--main-color-1)" }}>
              {authenticatedUser?.jop}
            </span>
          </div>
        </div>
      </div>
      <div
        className="languageConverter"
        onClick={() =>
          dispatch(changeLanguage(language === "ar" ? "en" : "ar"))
        }
      >
        <span>{language}</span>
        <span>{Globe}</span>
      </div>

      {/* Voice Notification Toggle */}
      {/* <div className="voice-notification-toggle" style={{ padding: "10px" }}>
        <label>
          <input
            type="checkbox"
            checked={voiceNotifications}
            onChange={() => setVoiceNotifications(!voiceNotifications)}
          />
          {language === "ar"
            ? "تشغيل الصوت للإشعارات"
            : "Enable Voice Notifications"}
        </label>
      </div> */}

      {/* Notification Icon */}
      <div
        className="notification-icon"
        onClick={toggleNotifications}
        style={{ position: "relative", cursor: "pointer", marginRight: "20px" }}
      >
        <Icon icon="mdi:bell-outline" width="24" height="24" />
        {unreadCount > 0 && (
          <span
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              background: "red",
              color: "white",
              borderRadius: "50%",
              padding: "2px 6px",
              fontSize: "12px",
            }}
          >
            {unreadCount}
          </span>
        )}
      </div>

      {/* Notifications Dropdown */}
      {showNotifications && (
        <div
          className="notifications-dropdown"
          style={{
            position: "absolute",
            top: "60px",
            right: "20px",
            width: "300px",
            maxHeight: "400px",
            overflowY: "auto",
            background: "#fff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <h3 style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
            {language === "ar" ? "الإشعارات" : "Notifications"}
          </h3>
          {notifications.length === 0 ? (
            <p style={{ padding: "10px" }}>
              {language === "ar" ? "لا توجد إشعارات" : "No notifications"}
            </p>
          ) : (
            notifications.map((notif) => (
              <div
                key={notif.id}
                style={{
                  padding: "10px",
                  borderBottom: "1px solid #f1f1f1",
                  backgroundColor: notif.read ? "#fff" : "#e6f7ff",
                  cursor: "pointer",
                }}
                onClick={() => markAsRead(notif.id)}
              >
                {language === "ar" ? (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: notif.read ? "normal" : "bold",
                    }}
                  >
                    تم إنشاء فاتورة جديدة باسم "{notif.submation_name}" بواسطة{" "}
                    {notif.agent_name || "غير معروف"}.
                  </p>
                ) : (
                  <p
                    style={{
                      margin: 0,
                      fontWeight: notif.read ? "normal" : "bold",
                    }}
                  >
                    New Invoice "{notif.submation_name}" created by{" "}
                    {notif.agent_name || "Unknown"}.
                  </p>
                )}
                <small>{new Date(notif.timestamp).toLocaleString()}</small>
              </div>
            ))
          )}
        </div>
      )}

      <ProfileMenu userData={authenticatedUser} />
    </header>
  );
}

export default Header;
