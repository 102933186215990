import { useSelector } from "react-redux";
import ContentNav from "../../datanavcontent";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import Table from "../../components/table";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import { base_url } from "../../data/consts";

export default function Invoices() {
  const language = useSelector((state) => state.language);
  const [fatoora, setFatoora] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });

  useEffect(() => {
    console.log(language);
  }, [language]);

  const headers = [
    { label: "#", dataIndex: "agent_id" },
    {
      label: language === "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: "product_name",
    },
    {
      label: language === "ar" ? "الحالة" : "Status",
      dataIndex: "status_text",
    },
    {
      label: language === "ar" ? "اسم المستخدم" : "User Name",
      dataIndex: "user_name",
    },
    {
      label: language === "ar" ? "رقم الفاتورة" : "Invoice Number",
      dataIndex: "fatoorah_id",
    },
    {
      label: language === "ar" ? "رقم الشخص" : "Phone Number",
      dataIndex: "user_phone",
    },
    {
      label: language === "ar" ? "المبلغ" : "Amount",
      dataIndex: "price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.price)}</span>,
    },
    {
      label: language === "ar" ? "المبلغ الكلي" : "Total Amount",
      dataIndex: "submission_price",
      type: "children",
      children: ({ row }) => <span>{parseFloat(row?.submission_price)}</span>,
    },
    {
      label: language === "ar" ? "مصاريف إدارية" : "Administrative Expenses",
      dataIndex: "administrative_expenses",
      type: "children",
      children: ({ row }) => (
        <span>
          {(+row?.price * (+row?.administrative_expenses / 100)).toFixed(1)}
        </span>
      ),
    },
    // {
    //   label: language === "ar" ? "صوره العميل" : "Agent Image",
    //   dataIndex: "agent_image",
    //   type: "children",
    //   children: ({ row }) => (
    //     <img src={row?.agent_info?.agent_image} alt="Agent" />
    //   ),
    // },
    {
      label: language === "ar" ? "اسم العميل" : "Agent Name",
      dataIndex: "agent_name",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_name}</p>,
    },
    {
      label: language === "ar" ? "رقم العميل" : "Agent Phone",
      dataIndex: "agent_phone",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_phone}</p>,
    },
    {
      label: language === "ar" ? "تاريخ الانشاء" : "Created At",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.created_at}</p>,
    },
    {
      label: language === "ar" ? "ايميل العميل" : "Agent Email",
      dataIndex: "agent_email",
      type: "children",
      children: ({ row }) => <p>{row?.agent_info?.agent_email}</p>,
    },
    // {
    //   label: language === "ar" ? "الرمز التعريفي" : "Agent Qr",
    //   dataIndex: "agent_qr_url",
    //   type: "children",
    //   children: ({ row }) => (
    //     <img src={row?.agent_info?.agent_qr_url} alt="Agent QR" />
    //   ),
    // },
  ];

  function handleGetAllData() {
    axios
      .post("https://baseeta-form.com/basita/getFatoorah.php")
      .then((res) => {
        if (res?.data) {
          setFatoora(res.data);
          setFilteredData(res.data); // Initialize with all data
        } else {
          toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "There's a problem");
      });
  }

  const [loader, setLoader] = useState(false);

  const [combineFatoorah, setCombineFatoorah] = useState([]);
  const [fatoorahStatus, setFatooraStatus] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleGetAllFawateerakData = async () => {
    setLoader(true);
    let allData = [];
    let currentPage = 1;
    const perPage = 15; // Based on the API's per_page value

    try {
      while (true) {
        const url = `https://app.fawaterk.com/api/v2/getInvoicesData?start_date=${startDate}&end_date=${endDate}&page=${currentPage}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization:
              "Bearer e6a44e951fab927a3dd8928a5574fad22e4e70afece3e875ab",
          },
        });

        const data = await response.json();

        if (data?.data && data?.data?.length) {
          // Append the current page's data to allData
          allData = [...allData, ...data.data];
        }

        // Check if we are on the last page
        if (currentPage >= data.pagination.last_page) {
          break;
        }

        // Move to the next page
        currentPage++;
      }

      // After fetching all pages, set the accumulated data to the state
    } catch (error) {
      console.error(error);
      toast.error(
        language === "ar"
          ? "حدث خطأ ما"
          : "There was an issue retrieving the data"
      );
    }
    console.log("allData", allData);
    let combined = [];
    const findElement = (invoiceId) => {
      return fatoora?.filter((fa_item) => fa_item?.invoice_id == invoiceId);
    };
    if (allData?.length && fatoora?.length) {
      allData?.map((item) => {
        const invoiceId = item?.id;
        console.log(findElement(invoiceId));
        if (findElement(invoiceId)?.length) {
          combined.push({ ...item, ...findElement(invoiceId)[0] });
        }
      });
      console.log(combined);
      setCombineFatoorah(combined);
      setFilteredData(combined); // Initialize with all data
    }
    setLoader(false);
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleGetAllFawateerakData();
      // handleGetAllData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    handleGetAllData();
    getAgents();
  }, []);
  const [doctorId, setDoctorId] = useState(null);
  const [agent, setAgent] = useState([]);
  const getAgents = async () => {
    const select = await axios.get(base_url + "select_agents.php");
    setAgent(select?.data?.message);
  };

  useEffect(() => {
    if (doctorId && fatoorahStatus) {
      setFilteredData(
        combineFatoorah?.filter(
          (item) =>
            item?.agent_info?.agent_id == doctorId &&
            item?.status_text == fatoorahStatus
        )
      );
    } else {
      setFilteredData(combineFatoorah);
    }
  }, [doctorId, fatoorahStatus, combineFatoorah]);

  return (
    <div className="h-container con-h">
      <DefaultLayout>
        <div className="childs">
          <div className="header">
            <ContentNav
              head={language === "ar" ? "فواتيرك" : "Your Invoices"}
            />
            <div className="searchContainer">
              <label htmlFor="search">
                {language === "ar" ? "ابحث هنا" : "Search here"}
              </label>
            </div>
          </div>
        </div>
        <div className="filterTable d-flex flex-wrap">
          <div className="filters" style={{ width: "min(850px, 100%)" }}>
            <label>{language === "ar" ? "تاريخ البدء" : "Start Date"}</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />

            <label>{language === "ar" ? "تاريخ الانتهاء" : "End Date"}</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />

            <button onClick={handleGetAllData}>
              {language === "ar" ? "بحث" : "Search"}
            </button>
          </div>

          <div className="filters" style={{ width: "min(850px, 100%)" }}>
            <div className="inputField">
              <label htmlFor="doctorId">
                {language === "ar" ? "اختيار الوكيل" : "Select Agent"}
              </label>
              <select
                name="doctorId"
                id="doctorId"
                onChange={(e) => setDoctorId(e.target.value || null)}
              >
                <option value={false}>{language === "ar" ? "الكل" : "All"}</option>
                {agent &&
                  agent.map((item) => (
                    <option key={item.agent_id} value={item.agent_id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="inputField">
              <label htmlFor="doctorId">
                {language === "ar" ? "حالة الدفع" : "Invoice Status"}
              </label>
              <select
                name="doctorId"
                id="doctorId"
                onChange={(e) => setFatooraStatus(e.target.value || null)}
              >
                <option value="">{language === "ar" ? "الكل" : "All"}</option>
                <option value={"paid"}>{"Paid"}</option>
                <option value={"unpaid"}>{"UnPaid"}</option>
              </select>
            </div>
          </div>
        </div>
        {loader ? (
          <Loader size="lg" />
        ) : (
          <Table headers={headers} body={filteredData} />
        )}
      </DefaultLayout>
    </div>
  );
}
