import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultLayout from "../../layout/defaultlayout";
import "./style.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { base_url } from "../../data/consts";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DoctorForm() {
  const [params] = useSearchParams();
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([{ value: "" }]);
  const [newElement, setNewElement] = useState({
    label: "",
    type: "",
    name: "",
  });
  const [formElements, setFormElements] = useState([]);
  const getForm = async () => {
    try {
      const select = await axios.post(
        base_url + "select_agents_forms.php",
        {
          agent_id: params?.get("doctor_id"),
        }
      );
      console.log(select?.data?.message);
      setFormElements(
        select?.data?.message?.map((item) => ({
          label: item?.form_label,
          type: item?.form_type,
          name: item?.form_name,
        }))
      );
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getForm();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleNewElementChange = (e) => {
    const { name, value } = e.target;
    setNewElement({
      ...newElement,
      [name]: value,
    });
  };

  const handleOptionChange = (index, e) => {
    const newOptions = options.slice();
    newOptions[index].value = e.target.value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { value: "" }]);
  };

  const addFormElement = () => {
    if (newElement.type === "radio" || newElement.type === "checkbox") {
      setFormElements([...formElements, { ...newElement, options }]);
      setOptions([{ value: "" }]);
    } else {
      setFormElements([...formElements, newElement]);
    }
    setNewElement({ label: "", type: "", name: "" });
  };

  const deleteFormElement = (index) => {
    const updatedFormElements = formElements.filter((_, i) => i !== index);
    setFormElements(updatedFormElements);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      form_data: formElements
        .map((item) => item?.type + "**" + item?.name + "**" + item?.label)
        .join("**basita**"),
      agent_id: params?.get("doctor_id"),
    };
    try {
      const response = await axios.post(base_url + "add_frorms.php", data);
      if (response?.data?.status === "success") {
        getForm();
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Form submission failed!");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedElements = Array.from(formElements);
    const [removed] = reorderedElements.splice(result.source.index, 1);
    reorderedElements.splice(result.destination.index, 0, removed);

    setFormElements(reorderedElements);
  };

  const renderFormElements = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="formElements">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formElements.map((element, index) => (
                <Draggable key={index} draggableId={String(index)} index={index}>
                  {(provided) => (
                    <div
                      className="form-group"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <label htmlFor={element.name}>{element.label}</label>
                      {element.type === "radio" || element.type === "checkbox" ? (
                        <div className="options-group">
                          {element.options.map((option, idx) => (
                            <div key={idx} className="option-group">
                              <input
                                type={element.type}
                                id={`${element.name}-${idx}`}
                                name={element.name}
                                value={option.value}
                                onChange={handleInputChange}
                              />
                              <label htmlFor={`${element.name}-${idx}`}>
                                {option.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <input
                          type={element.type}
                          id={element.name}
                          name={element.name}
                          value={formValues[element.name] || ""}
                          onChange={handleInputChange}
                        />
                      )}
                      <button
                        type="button"
                        onClick={() => deleteFormElement(index)}
                        className="delete-button"
                      >
                        حذف
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const elementTypes = [
    "text",
    "number",
    "email",
    "date",
    // "file",
    // "radio",
    // "checkbox",
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="form-creator">
              <h3>Create Form Element</h3>
              <div className="form-group">
                <label>الاسم فوق الانبوت</label>
                <input
                  type="text"
                  name="label"
                  value={newElement.label}
                  onChange={handleNewElementChange}
                  placeholder="مثال: رقم التليفون"
                />
              </div>
              <div className="form-group">
                <label>نوع المدخل</label>
                <select
                  name="type"
                  value={newElement.type}
                  onChange={handleNewElementChange}
                >
                  <option value="">اختر نوع</option>
                  {elementTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>قيمة المدخل</label>
                <input
                  type="text"
                  name="name"
                  value={newElement.name}
                  onChange={handleNewElementChange}
                  placeholder="مثال : phone_number"
                />
              </div>
              {(newElement.type === "radio" ||
                newElement.type === "checkbox") && (
                <div className="options-group">
                  <label>الاختيارات</label>
                  {options.map((option, index) => (
                    <div key={index} className="form-group">
                      <input
                        type="text"
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, e)}
                      />
                    </div>
                  ))}
                  <button type="button" onClick={addOption}>
                    أضف اختيار
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={addFormElement}
                className="add-element-button"
              >
                إضافة عنصر
              </button>
            </div>
            <form onSubmit={handleSubmit} className="dynamic-form">
              {renderFormElements()}
              <button type="submit" className="submit-button">
                تأكيد
              </button>
            </form>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
}

export default DoctorForm;
